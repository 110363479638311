<template>
  <ItemWrapper>
    <v-row justify-start wrap>
      <v-col cols="12">
        <AppCard :title="$t('Zone')" :prev-route="prevRoute" :loading="pending">
          <v-text-field
            v-model="filterRecordsQuery"
            placeholder="Records filtern..."
            :append-outer-icon="filterRecordsQuery ? 'mdi-close' : undefined"
            @click:append-outer="filterRecordsQuery = ''"
          />
          <v-simple-table>
            <template #default>
              <thead>
                <tr>
                  <th class="record-type">Typ</th>
                  <th class="record-name">Name</th>
                  <th class="record-content">Wert</th>
                  <th class="record-ttl">TTL</th>
                  <th class="record-prio">Priorität</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                <RecordItem
                  v-for="(record, index) in filteredRecords"
                  :key="record.id"
                  :index="index"
                  :record="record"
                />
                <AddRecordItem />
              </tbody>
            </template>
          </v-simple-table>
        </AppCard>
      </v-col>

      <v-col cols="12" sm="6">
        <AppCard :title="$t('Actions')">
          <v-btn color="info" class="mr-2 mt-2" @click="showResetZoneModal = true">
            <i class="i-return" /> Zurücksetzen
          </v-btn>
          <v-btn color="error" class="mr-2 mt-2" @click="showDeleteZoneModal = true">Zone löschen</v-btn>
          <v-btn color="success" class="mt-2" @click="onEditZone">Zone Speichern</v-btn>
        </AppCard>
      </v-col>

      <AppDialog :is-open="showDeleteZoneModal" max-width="500" @onCloseDialog="showDeleteZoneModal = false">
        <template slot="title">Zone löschen?</template>

        <p>
          Bist du sicher, dass du die Zone <b>{{ name }}</b> löschen möchtest? Diese Aktion kann nicht rückgängig
          gemacht werden.
        </p>

        <v-btn color="default" class="mr-2 mt-2" @click="showDeleteZoneModal = false">Abbrechen</v-btn>
        <v-btn color="error" class="mt-2" @click="onDeleteZone">Zone löschen</v-btn>
      </AppDialog>

      <AppDialog :is-open="showResetZoneModal" max-width="500" @onCloseDialog="showResetZoneModal = false">
        <template slot="title">Zone zurücksetzen?</template>

        <p>
          Bist du sicher, dass du alle Records der Zone
          <b>{{ name }}</b> zurücksetzen möchtest? Diese Aktion kann nicht rückgängig gemacht werden.
        </p>

        <v-btn color="default" class="mr-2 mt-2" @click="showResetZoneModal = false">Abbrechen</v-btn>
        <v-btn color="error" class="mt-2" @click="onResetZone">Zone zurücksetzen</v-btn>
      </AppDialog>
    </v-row>
  </ItemWrapper>
</template>

<script>
import { computed, defineComponent, onUnmounted, ref } from '@vue/composition-api'
import { mapMultiRowFields } from 'vuex-map-fields'
import { mapFields } from 'vuex-composition-map-fields'

import AddRecordItem from '@/views/Zones/ZonesAddRecordItem'
import AppCard from '@/components/UI/AppCard'
import AppDialog from '@/components/UI/AppDialog'
import ItemWrapper from '@/layouts/ItemWrapper'
import RecordItem from '@/views/Zones/ZonesRecordItem'

export default defineComponent({
  name: 'ViewZone',
  components: {
    AddRecordItem,
    AppCard,
    AppDialog,
    ItemWrapper,
    RecordItem,
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      // vm.prevRoute = from.fullPath === '/' ? vm.prevRoute : from.fullPath
    })
  },
  setup(props, { root: { $store, $router } }) {
    const prevRoute = ref('/Zones')
    const showDeleteZoneModal = ref(false)
    const showResetZoneModal = ref(false)

    // store
    const pending = computed(_ => $store.state.zone.pending)
    const zone = computed(_ => $store.state.zone.zone)
    const pendingRefresh = computed(_ => $store.state.zone.pendingRefresh)
    const errors = computed(_ => $store.state.zone.errors)
    const zoneMapFields = mapFields('zone', [
      'zone.name',
      'zone.ip',
      'zone.template',
      'zone.connection',
      'filterRecordsQuery',
    ])
    const getZone = _ => $store.dispatch('zone/getZone')
    const editZone = _ => $store.dispatch('zone/editZone')
    const deleteZone = _ => $store.dispatch('zone/deleteZone')
    const resetZone = _ => $store.dispatch('zone/resetZone')
    const clearZone = _ => $store.dispatch('zone/clearZone')
    const addToast = toast => $store.commit('notifications/ADD_TOAST', toast)

    getZone()
      .then(r => r)
      .catch(error => {
        if (error.response.status === 404) {
          $router.push({ name: 'notFound' })
        }
      })

    const onDeleteZone = _ => {
      if (pending.value || pendingRefresh.value) {
        return
      }

      deleteZone().then(({ errors }) => {
        showDeleteZoneModal.value = false
        if (errors) {
          addToast({
            msg: 'Löschung fehlgeschlagen',
            type: 'error',
          })
        } else {
          addToast({
            msg: 'Zone erfolgreich gelöscht',
            type: 'success',
          })
          $router.push({ path: prevRoute.value })
        }
      })
    }

    const onEditZone = _ => {
      if (pending.value || pendingRefresh.value) {
        return
      }

      editZone().then(({ errors }) => {
        if (errors) {
          addToast({
            msg: 'Bearbeitung fehlgeschlagen',
            type: 'error',
          })
        } else {
          addToast({
            msg: 'Zone erfolgreich bearbeitet',
            type: 'success',
          })
          $router.push({ path: prevRoute.value })
        }
      })
    }

    const onResetZone = _ => {
      if (pending.value || pendingRefresh.value) {
        return
      }

      resetZone().then(({ errors }) => {
        showResetZoneModal.value = false
        if (errors) {
          addToast({
            msg: 'Zurücksetzung fehlgeschlagen',
            type: 'error',
          })
        } else {
          addToast({
            msg: 'Zone erfolgreich zurückgesetzt',
            type: 'info',
          })
        }
        // if (!errors)
        // this.$router.push({
        //   name: 'zones',
        // })
      })
    }

    onUnmounted(_ => {
      clearZone()
    })

    return {
      prevRoute,
      showDeleteZoneModal,
      showResetZoneModal,
      pending,
      ...zoneMapFields,
      zone,
      errors,
      onDeleteZone,
      onEditZone,
      onResetZone,
    }
  },
  computed: {
    ...mapMultiRowFields('zone', ['zone.zone_records']),
    filteredRecords() {
      if (this.filterRecordsQuery !== '') {
        return this.zone_records.filter(record =>
          record.name.toLowerCase().includes(this.filterRecordsQuery.toLowerCase()),
        )
      }
      return this.zone_records
    },
  },
})
</script>
