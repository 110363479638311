<template>
  <tr>
    <td>
      <v-select v-model="type" dense :items="dnsRecordTypes" />
    </td>
    <td>
      <v-text-field v-model="name" single-line dense placeholder="Name" />
    </td>
    <td>
      <v-text-field v-model="content" single-line dense placeholder="Wert" />
    </td>
    <td>
      <v-text-field v-model="ttl" single-line dense placeholder="TTL" />
    </td>
    <td>
      <v-text-field v-model="prio" single-line dense placeholder="Priorität" />
    </td>
    <td>
      <v-btn x-small color="success" @click="onAddRecord">Record Hinzufügen</v-btn>
    </td>
  </tr>
</template>

<script>
import { defineComponent, computed } from '@vue/composition-api'
import { mapFields } from 'vuex-composition-map-fields'

import { dnsRecordTypes } from '@/config'

export default defineComponent({
  name: 'AddRecordItem',
  setup(props, { root: { $store } }) {
    // store
    const pending = computed(_ => $store.state.zone.pending)

    const onAddRecord = _ => {
      if (pending.value) {
        return
      }
      $store.dispatch('zone/addRecord')
    }

    return {
      pending,
      dnsRecordTypes,
      ...mapFields('zone', [
        'zone_record.name',
        'zone_record.type',
        'zone_record.content',
        'zone_record.ttl',
        'zone_record.prio',
      ]),
      onAddRecord,
    }
  },
})
</script>
